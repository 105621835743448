<template>
  <div class="news">
    <!-- PC -->
    <div
      class="
        productTop
        hidden-xs-only
        wow
        animate__animated animate__fadeInDown
      "
    >
      <img src="../../assets/images/swiper/swiperItem1.png" alt="" />
    </div>
    <!-- mobile -->
    <div
      class="
        mobileProductTop
        hidden-sm-and-up
        wow
        animate__animated animate__fadeInDown
      "
    >
      <img src="../../assets/images/mobile/index/index_1.png" alt="" />
    </div>
    <div class="location hidden-xs-only">
        <div class="section wow animate__animated animate__fadeInLeft">
            <div class="tarbar">
                <span>您的位置：</span>
                <span @click="handleChange(1)">首页</span>
                <span>></span>
                <span class="newsCenter">新闻中心</span>
            </div>
        </div>
    </div>
    <div class="newsContent hidden-xs-only">
        <div class="section">
            <div class="title">
                <div class="title_one">
                    <span>新闻中心</span>
                </div>
            </div>
            <div class="content" v-if="newsList.length!==0">
                <div class="Item wow animate__animated animate__fadeInDown" v-for="(item,index) in newsList" :key="index">
                    <div class="ItemContent" @click="goDetail(item)">
                        <img v-if="item.picture" :src="url + item.picture.fileUrl" alt="">
                        <img v-else src="../../assets/images/index/none.png" alt="">
                        <div class="title">{{item.title}}</div>
                        <div class="content" v-html="item.profile"></div>
                        <div v-if="item.createTime" class="time">{{item.createTime.slice(5,11)}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <el-pagination
        v-if="newsList.length!==0"
        :total="total"
        class="hidden-xs-only"
        :page-size="Info.pageSize"
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        background
    >
    </el-pagination>
    <!-- mobile -->
    <div class="mobileContent hidden-sm-and-up wow animate__animated animate__fadeInDown" v-if="mobileList.length!==0">
        <div class="item wow animate__animated animate__fadeInDown" v-for="(item,index) in mobileList" :key="index"
        @click="goMobileDetail(item)">
            <img v-if="item.picture" :src="url + item.picture.fileUrl" alt="">
            <img v-else src="../../assets/images/index/none.png" alt="">
            <div class="title">{{item.title}}</div>
            <div class="content" v-html="item.profile"></div>
            <div v-if="item.createTime" class="time">{{item.createTime.slice(5,11)}}</div>
        </div>
    </div>
  </div>
</template>
<script>
import { noticePage } from '../../api/management'
export default {
  data() {
    return {
        url:process.env.VUE_APP_WEB_URL,
        total:null,
        Info:{
            pageSize:6,
            current:1,
            displaySeen:true
        },
        newsList:[],
        mobileList:[],
        mobileInfo:{
            pageSize:1000,
            current:1,
            displaySeen:true
        },
      
    };
  },
  created() {
    this.getPCorMobile()

  },
  mounted() {
    new this.$wow.WOW().init();
  },
  methods:{
    getPCorMobile() {
        if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
           this.getMobileNoticePage()
        } else {
           this.getNoticePage()
        }
    },
    // PC
    async getNoticePage() {
        const res = await noticePage(this.Info)
        // console.log(res);
        this.newsList = res.data.records
        this.total = res.data.total
    },
    // mobile
    async getMobileNoticePage() {
        const res = await noticePage(this.mobileInfo)
        // console.log(res);
        this.mobileList = res.data.records
        // this.total = res.data.total
    },
    handleChange(num) {
      this.$store.commit("changeNum",num)
      this.$router.push('/index')
    },
    handleCurrentChange(value) {
        this.Info.current = value
        this.getNoticePage()
    },
    goDetail(item) {
        if(item.link) {
            window.open(item.link)
        }else {
            this.$router.push({path:'/newsDetail',query:{id:item.id}})
        }
    },
    goMobileDetail(item) {
        if(item.link) {
            window.open(item.link)
        }else {
            this.$router.push({path:'/newsDetail',query:{id:item.id}})
        }
    }
  }
};
</script>
<style lang="scss">
.news {
  padding-top: 92px;
  .productTop {
    img {
      width: 100%;
      height: 482px;
    }
  }
  .location {
    padding: 50px 0;
    .section {
        font-size: 18px;
        .tarbar {
            cursor: pointer;
            .newsCenter {
                color: #197dbe;
            }
        }
    }
  }
  .newsContent {
    .section {
        .title {
            padding-bottom: 82px;
            text-align: center;
            display: flex;
            flex-direction: column;
            .title_one {
            span {
                font-size: 36px;
                display: inline-block;
                background-image: linear-gradient(
                to right,
                #25a9e0,
                #187aba
                ); //最后三行为文字渐变CSS
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            }
            .title_two {
            margin-top: 8px;
            span {
                font-size: 16px;
                display: inline-block;
                background-image: linear-gradient(
                to right,
                #25a9e0,
                #187aba
                ); //最后三行为文字渐变CSS
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            }
        }
        .content{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .Item {
                padding-top:60px;
                width: 600px;
                height: 490px;
                .ItemContent{
                    overflow: hidden;
                    width: 512px;
                    img {
                        width: 512px;
                        height: 186px;
                        transition: all 0.3s linear;
                    }
                    .title{
                        width: 512px;
                        font-size: 24px;
                        text-align: left;
                        padding: 36px 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        display: inline-block;
                    }
                    .content{
                        font-size: 18px;
                        line-height: 26px;
                        overflow : hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                    }
                    .time{
                        color: #ededed;
                        font-weight: 600;
                        font-size: 60px;
                        padding: 10px 0 30px 0;
                    }
                }
            }
            .Item:first-child {
                padding-top:0;
            }
            .Item:nth-child(2) {
                padding-top:0;
            }
            .Item:nth-child(2n) {
                padding-left:88px;
                border-bottom: 1px solid #ededed;
            }
            .Item:nth-child(2n-1) {
                border-right: 1px solid #ededed;
                border-bottom: 1px solid #ededed;
            }
            .Item:nth-child(5) {
                border-bottom: none;
            }
            .Item:nth-child(6) {
                border-bottom: none;
            }
            .Item:hover {
                .ItemContent {
                    img {
                        transform: scale(1.1);
                    }
                    .title,.content,.time {
                        // display: inline-block;
                        background-image: linear-gradient(
                        to right,
                        #25a9e0,
                        #187aba
                        ); //最后三行为文字渐变CSS
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }
    }
  }
  .el-pagination {
    .el-pager {
        li {
            height: 48px!important;
            line-height: 48px!important;
            min-width: 48px!important;
            font-size: 18px!important;
        }
    }
    padding: 30px 0;
    .btn-prev,.btn-next {
        display: none;
    }
  }
}
@media (max-width: 768px) {
  .news {
    padding-top: 0;
    .mobileProductTop {
      img {
        height: 4rem;
      }
    }
    .mobileContent {
        margin-top: 1rem;
        padding: 0 .4rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .item {
            width: 100%;
            img {
                height: 3rem;
                width: 100%;
            }
            .title {
                width: 100%;
                padding: .25rem 0;
                font-size: .33rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
            }
            .content{
                line-height: .4rem;
                color: #666666;
                overflow : hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
            }
            .time{
                font-size: .6rem;
                padding: .25rem 0 .4rem 0;
                font-weight: 600;
                color: #ededed;
            }
        }
        .item:hover {
            img {
                transform: scale(1.1);
            }
            .title,.content,.time {
                // display: inline-block;
                background-image: linear-gradient(
                to right,
                #25a9e0,
                #187aba
                ); //最后三行为文字渐变CSS
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
  }
}
</style>